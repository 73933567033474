/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-unescaped-entities */
import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Img from "gatsby-image"
import tw from "tailwind.macro"
import { StaticQuery, graphql } from "gatsby"
import LinkTo from "../components/LinkTo"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AppWrapper from "../views/AppWrapper"
import Content, { Inner } from "../elements/Content"
import Hero from "../views/Hero"
import { BigTitleLight, H2, P, PLight } from "../elements/Text"
import Button from "../elements/Button"

const SectionWrapper = styled(Inner)`
  ${tw`w-full lg:flex lg:flex-wrap`}
`

const SectionContainer = styled.div`
  ${tw`lg:w-1/2 xl:w-1/3 text-center`};
  ${tw`lg:flex lg:flex-col`};
`

const Title = styled.span`
  ${tw`block mt-4`};
`

const Image = styled(Img)`
  filter: grayscale(15%);
  opacity: 0.65;
`

const ImageWrapper = styled.div`
  ${tw`my-8`};
`

const SectionHeader = styled(H2)`
  ${tw`mb-0 w-full`};

  &:hover {
    ${Image} {
      filter: none;
      opacity: 1;
    }
  }
`

const Separator = styled.span`
  ${tw`w-full bg-grey-light h-px mx-auto my-6 block`};
`

const SectionContents = styled.div`
  ${tw`flex flex-col flex-grow justify-between lg:px-6`};
`

const Section = ({ children, ...props }) => {
  const { title, slug, image } = props

  return (
    <SectionContainer>
      <SectionHeader>
        <LinkTo href={slug}>
          <LogoImage image={image} />
          <Title>{title}</Title>
        </LinkTo>
      </SectionHeader>
      <SectionContents>{children}</SectionContents>
      <Separator />
    </SectionContainer>
  )
}

Section.defaultProps = {
  image: null,
}

Section.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  image: PropTypes.object,
}

const LogoImage = ({ ...props }) => {
  const { image } = props

  return (
    <ImageWrapper>
      <Image
        fluid={image.childImageSharp.fluid}
        style={{ maxHeight: 60, minHeight: 60, maxWidth: 140, margin: "auto" }}
        imgStyle={{ objectFit: "contain" }}
      />
    </ImageWrapper>
  )
}

LogoImage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  image: PropTypes.object.isRequired,
}

const CaseStudiesPage = () => (
  <StaticQuery
    query={graphql`
      query ListCSQuery {
        allMarkdownRemark(
          filter: {
            fileAbsolutePath: { regex: "/(case-studies)/" }
            frontmatter: { hidden: { ne: true } }
          }
          sort: { order: DESC, fields: [frontmatter___date] }
        ) {
          edges {
            node {
              fields {
                slug
              }
              frontmatter {
                date(formatString: "MMMM Do YYYY")
                title
                subtitles
                hidden
                logoImage {
                  childImageSharp {
                    fluid(maxHeight: 60) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      return (
        <Layout>
          <SEO title="Case Studies" />
          <AppWrapper>
            <Hero isDark style={{ zIndex: 20 }}>
              <BigTitleLight>Case Studies</BigTitleLight>
              <PLight />
            </Hero>
            <Content>
              <SectionWrapper>
                {data.allMarkdownRemark.edges.map(({ node }, i) => {
                  return (
                    <Section
                      key={`case-study-${i}`}
                      title={node.frontmatter.title}
                      slug={node.fields.slug}
                      image={node.frontmatter.logoImage}
                    >
                      <P>{node.frontmatter.subtitles}</P>
                      <LinkTo href={node.fields.slug}>
                        <Button>Read More</Button>
                      </LinkTo>
                    </Section>
                  )
                })}
              </SectionWrapper>
            </Content>
          </AppWrapper>
        </Layout>
      )
    }}
  />
)

export default CaseStudiesPage
